import Html from 'SourceComponent/Html';
import Image from 'SourceComponent/Image';
import { ImageRatio } from 'SourceComponent/Image/Image.type';
import {
    SliderWidgetComponent as SourceSliderWidget,
} from 'SourceComponent/SliderWidget/SliderWidget.component';

/** @namespace Theme/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidget {
    renderSlideImage(slide, i) {
        const { onLoad } = this.props;
        const {
            slide_text,
            isPlaceholder,
            title: block,
        } = slide;

        const loading = i === 0 ? 'eager' : 'lazy';

        return (
            <figure
              block="SliderWidget"
              elem="Figure"
              key={ i }
            >
                <Image
                  mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  src={ this.getSlideImage(slide) }
                  isPlaceholder={ isPlaceholder }
                  onImageLoad={ onLoad }
                  loading={ loading }
                />
                <figcaption
                  block="SliderWidget"
                  elem="Figcaption"
                  mix={ { block } }
                >
                    <Html content={ slide_text || '' } />
                </figcaption>
            </figure>
        );
    }
}

export default SliderWidgetComponent;
